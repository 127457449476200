import React, { useState } from "react"
import { Link } from "gatsby"
import classnames from "classnames"
import flatten from "lodash/flatten"
import uniq from "lodash/uniq"
import first from "lodash/first"
import Layout from "../components/layout"
import SEO from "../components/seo"

import { useAllProducts } from "../hooks/useAllProducts"

import "./products.css"

const Products = () => {
  const data = useAllProducts()

  const categories = uniq(flatten(data.map(p => p.categories)))

  const [selectedCategory, selectCategory] = useState("")

  const filteredProducts = data.filter(p =>
    selectedCategory ? p.categories.includes(selectedCategory) : p
  )

  console.log(first(first(data).image))

  return (
    <Layout mainclass="product-page">
      <SEO title="Tuotteet" />
      <div className="product-nav">
        <h1>Tuotteet</h1>

        <div>
          <button
            onClick={() => selectCategory("")}
            className={classnames("category", {
              selected: !selectedCategory,
            })}
          >
            Kaikki
          </button>
          {categories.map(c => (
            <button
              key={c}
              onClick={() => selectCategory(c)}
              className={classnames("category", {
                selected: selectedCategory === c,
              })}
            >
              {c}
            </button>
          ))}
        </div>
      </div>
      <div className="products-container">
        {filteredProducts.map(p => {
          const img = first(p.image)
            ? `/products/${first(p.image)}`
            : "https://via.placeholder.com/250"
            console.log(img)
          return (
            <Link key={p.slug} to={`/tuote/${p.slug}`} className="product-card">
              <div
                className="product-image"
                style={{ backgroundImage: `url("${img}")` }}
              ></div>
              <div className="product-info">
                <p className="product-title">
                  <strong>{p.name}</strong>
                </p>
                <p className="btn">Lue lisää</p>
              </div>
            </Link>
          )
        })}
      </div>
    </Layout>
  )
}

export default Products
